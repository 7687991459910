import logo from './logo.png';
import banner from './banner.jpg'
import cetecom_logo from './cetecom-logo.png'
import cetecom_logo2 from './cetecom-logo2.png'
import chatAvatar from './chatbot-avatar.png'
import srcd_logo from './srcd-logo.png'

import './App.css';

import { useState, useEffect } from 'react';
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import NavLink from 'react-bootstrap/esm/NavLink';
import NavItem from 'react-bootstrap/esm/NavItem';
import TabContent from 'react-bootstrap/esm/TabContent';

import Chatbot from './components/chatbot';


function App() {

  /* Validaciones Contactanos */
  const [inputNombre, setInputNombreValue] = useState('');
  const [isInputNombreValid, setIsInputNombreValid] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inputMensaje, setInputMensajeValue] = useState('');
  const [isInputMensajeValid, setIsInputMensajeValid] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false)

  const [estadoModal, setEstadoModal] = useState(false);
  const [mensajeModal, setMensajeModal] = useState('');

  const cerrarModal = () => setEstadoModal(false);
  const abrirModal = () => setEstadoModal(true);

  const [chatBotInputValue, setChatBotInputValue] = useState('');
  const manejarCambioDesdeComponente = (valor) => {
    setChatBotInputValue(valor);
  };


  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    if (isCaptchaValid || isInputMensajeValid || isInputNombreValid || isEmailValid) {
      const formData = {
        nombre: inputNombre,
        email: email,
        mensaje: inputMensaje,
        token: isCaptchaValid,
      };

      const response = await fetch('/enviar_formulario', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      setTimeout(() => {
        setIsSubmitDisabled(false);
      }, 5000);
      const data = await response.json();
      if (data.success) {
        setMensajeModal('¡Gracias por contactarte con nosotros!')
        abrirModal()
      } else {
        abrirModal()
        setMensajeModal('Tu mensaje no ha podido ser enviado')
      }
    }
  }

  const agruparPorPares = (array) => {
    let result = [];
    for (let i = 0; i < array.length; i += 2) {
      result.push(array.slice(i, i + 2));
    }
    return result;
  };

  const validarCaptcha = (e) => {
    setIsCaptchaValid(e);
  }

  const invalidarCaptcha = (e) => {
    setIsCaptchaValid(false)
  }

  const handleInputNombreChange = (event) => {
    const value = event.target.value;
    const noNumbers = /^[^0-9]*$/;
    const isValid = value.trim() !== '' && noNumbers.test(value);

    setInputNombreValue(value);
    setIsInputNombreValid(isValid);
  };

  const handleEmailInput = (event) => {
    const value = event.target.value;
    setEmail(value);
    validarEmail(value);
  };

  const validarEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(duocuc\.cl|duoc\.cl)$/i;;
    setIsEmailValid(emailRegex.test(email));
  };

  const handleInputMensajeChange = (event) => {
    const value = event.target.value;
    setInputMensajeValue(value);
    setIsInputMensajeValid(value.trim() !== '');
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* Barras de busqueda */

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm1, setSearchTerm1] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');

  const handleSearch = () => setSearchQuery(searchTerm);
  const handleSearch1 = () => setSearchQuery1(searchTerm1);
  const handleSearch2 = () => setSearchQuery2(searchTerm2);

  const [preguntasPorCategoria, setPreguntasPorCategoria] = useState({});
  const [videosPorCategoria, setVideosPorCategoria] = useState({});
  const [softwarePorCatalogo, setSoftwarePorCatalogo] = useState({});
  const [profesionales, setProfesionales] = useState([]);
  const [quienessomos, setQuienesSomos] = useState([]);


  const [activeTab, setActiveTab] = useState("SOPORTE ALUMNOS");
  const handleTabChange = (eventKey) => {
    setActiveTab((prevActiveTab) => 
      prevActiveTab === eventKey ? null : eventKey
    );
  };

  useEffect(() => {
    const input = document.getElementById('busquedaCatalogoSoftware');
    input && input.blur();
  }, []);

  useEffect(() => {

    document.title = 'Servicios Digitales DuocUC';

    {/* Se hace peticion al servidor para traer datos y guardarlos en constantes */ }
    const fetchData = async () => {
      try {
        const response = await axios.get('/test');
        setPreguntasPorCategoria(response.data.preguntas_por_categoria);
        setVideosPorCategoria(response.data.videos_por_categoria);
        setSoftwarePorCatalogo(response.data.software_por_catalogo);
        setProfesionales(response.data.galeria_equipo);
        setQuienesSomos(response.data.quienes_somos);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const filteredSoftwarePorCatalogo = Object.fromEntries(
    Object.entries(softwarePorCatalogo).map(([catalogo, softwares]) => {
      const filteredSoftwares = softwares.filter((software) =>
        software.nombre.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return [catalogo, filteredSoftwares];
    })
  );
  const filteredCategoriesSoftware = Object.entries(filteredSoftwarePorCatalogo).filter(
    ([, softwares]) => softwares.length > 0
  );

  const filteredVideosPorCategoria = Object.fromEntries(
    Object.entries(videosPorCategoria).map(([categoria, videos]) => {
      const filteredVideos = videos.filter((video) =>
        video.nombre.toLowerCase().includes(searchQuery1.toLowerCase())
      );
      return [categoria, filteredVideos];
    })
  );
  const filteredCategoriesVideos = Object.entries(filteredVideosPorCategoria).filter(
    ([, videos]) => videos.length > 0
  );

  const filteredPreguntasPorCategoria = Object.fromEntries(
    Object.entries(preguntasPorCategoria).map(([categoria, preguntas]) => {
      const filteredPreguntas = preguntas.filter((pregunta) =>
        pregunta.pregunta.toLowerCase().includes(searchQuery2.toLowerCase())
      );
      return [categoria, filteredPreguntas];
    })
  );
  const filteredCategoriesPreguntas = Object.entries(filteredPreguntasPorCategoria).filter(
    ([, preguntas]) => preguntas.length > 0
  );

  {/* Estas funciones manipula los datos de preguntas frecuentes(filtra y ordena por categoria) y los manda hacia el componente de chatbot */ }
  const filteredPreguntasPorCategoria1 = Object.fromEntries(
    Object.entries(preguntasPorCategoria).map(([categoria, preguntas]) => {
      const filteredPreguntas = preguntas.filter((pregunta) =>
        pregunta.pregunta.toLowerCase().includes(chatBotInputValue.toLowerCase())
      );
      return [categoria, filteredPreguntas];
    })
  );
  const filteredCategoriesPreguntas1 = Object.entries(filteredPreguntasPorCategoria1).filter(
    ([, preguntas]) => preguntas.length > 0
  );


  const [inputAbierto, setInputAbierto] = useState(null);
  const toggleInput = (id) => {
    setInputAbierto(prevId => (prevId === id ? null : id));
  };

  // Función para enviar datos de seguimiento
  async function enviarDatosAServidor(ruta, tiempo, componente, categoria) {
    const datos = {
      ruta: ruta,
      tiempo: tiempo,
      componente: componente,
      categoria: categoria
    };

    try {
      const response = await fetch('seguimiento/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datos),
      });
      if (!response.ok) {
        throw new Error('Error al enviar los datos');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  const [categoria, setcategoria] = useState("Inicio de página");
  const [componenteAnterior, setComponenteAnterior] = useState("Inicio de página");
  const [rutaActual, setRutaActual] = useState(window.location.href);
  let tiempoInicio = Date.now();

  useEffect(() => {
    const handleWindowClose = (event) => {
      const tiempoActual = Date.now();
      const tiempoTranscurrido = (tiempoActual - tiempoInicio) / 1000;
      enviarDatosAServidor(rutaActual, tiempoTranscurrido, componenteAnterior, categoria);
    };

    window.addEventListener('beforeunload', handleWindowClose);

    const handleRouteChange = () => {
      setRutaActual(window.location.href);
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [componenteAnterior, rutaActual, categoria, tiempoInicio]);


  const [disabledButtons, setDisabledButtons] = useState({});
  let ultimoEnvio = 0;
  function handleClick(id_componente, id_categoria) {
    const tiempoActual = Date.now();
    let tiempoTranscurrido = (tiempoActual - tiempoInicio) / 1000;

    // tiempo limite para evaluar interaccion (600s = 10 minutos)
    if (tiempoTranscurrido > 600) {
      tiempoTranscurrido = 600;
    }

    setDisabledButtons((prevState) => ({
      ...prevState,
      [id_componente]: true,
    }));

    setTimeout(() => {
      setDisabledButtons((prevState) => ({
        ...prevState,
        [id_componente]: false,
      }));
    }, 10000);

    if ((tiempoTranscurrido >= 20 || id_componente !== componenteAnterior) && (tiempoActual - ultimoEnvio) / 1000 >= 10) {
      enviarDatosAServidor(rutaActual, tiempoTranscurrido, componenteAnterior, categoria);
      setComponenteAnterior(id_componente);
      tiempoInicio = Date.now();
      setRutaActual(window.location.href);
      setcategoria(id_categoria);
      ultimoEnvio = Date.now();

    }
  }


  function handleVideoClick(videoId, videoName) {

    handleClick(videoName, categoria4)
    const iframe = document.getElementById(`player-${videoId}`);
    iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');

    // Deshabilitar el overlay para permitir la reproducción
    const overlay = iframe.nextSibling;
    if (overlay) {
      overlay.style.pointerEvents = 'none';
    }
  }
  //categorizacion de click
  const [categoria1] = useState("Navbar");
  const [categoria3] = useState("Catálogo de Software");
  const [categoria4] = useState("Videotutoriales");
  const [categoria5] = useState("Preguntas Frecuentes");
  const [categoria6] = useState("Formulario Contacto");
  const [categoria7] = useState("ChatBot");
  //fin de cosas necesarias par el seguimiento

  //boton flotante de volver arriba / volver inicio
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //control de acordeon de software al realizar busqueda
  const [activeAccordion, setActiveAccordion] = useState(null);

  useEffect(() => {
    if (searchQuery === '') {
      return;
    }
    if (filteredCategoriesSoftware.length > 0) {
      setActiveAccordion(filteredCategoriesSoftware[0][0]);
    }
  }, [searchQuery, filteredCategoriesSoftware]);
  
  const handleAccordionClick = (catalogo) => {
    setActiveAccordion((prevActiveAccordion) => 
      prevActiveAccordion === catalogo ? null : catalogo
    );
  };
  //control de acordeon de videotutorial al realizar busqueda

  
  useEffect(() => {
    if (searchQuery1 === '') {
      return;
    }
    if (filteredCategoriesVideos.length > 0) {
      setActiveTab(filteredCategoriesVideos[0][0]); // Abre el primer tab con resultados
    }
  }, [searchQuery1, filteredCategoriesVideos]);
  //control de acordeon de Preguntas Frecuentes al realizar busqueda
  useEffect(() => {
  if (searchQuery2 === '') {
    return;
  }
  if (filteredCategoriesPreguntas.length > 0) {
    setInputAbierto(filteredCategoriesPreguntas[0][0]);
  }
}, [searchQuery2, filteredCategoriesPreguntas]);

// evento de apertura o cierre chatbot necessario para control de boton volver
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  useEffect(() => {
    const handleChatbotOpen = () => setIsChatbotOpen(true);
    const handleChatbotClose = () => setIsChatbotOpen(false);

    window.addEventListener('chatbotOpened', handleChatbotOpen);
    window.addEventListener('chatbotClosed', handleChatbotClose);

  
    return () => {
      window.removeEventListener('chatbotOpened', handleChatbotOpen);
      window.removeEventListener('chatbotClosed', handleChatbotClose);
    }
  })

  return (

    <div>


{/* NavBar */}



      <div className='divBarraNavegacion fontInter'>
        <Navbar expand="lg" className="myNavBar text-white">
          <Container className="contenedorNavBar">
            <Navbar.Brand href="https://www.duoc.cl/">
              <img
                src={logo}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
                style={{ width: '175px', height: 'auto' }}
              />
            </Navbar.Brand>
          </Container>
          <Container className="contenedorNavBar">
            {/* <Navbar.Brand href="https://www.duoc.cl/">
              <img
                src={logo}
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
                style={{ width: '175px', height: 'auto' }}
              />
            </Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto flex-grow-1">
                <Nav.Link href="#profesionales" onClick={() => handleClick("QUIÉNES SOMOS", categoria1)}>QUIÉNES SOMOS</Nav.Link>
                <NavDropdown title="CATÁLOGOS" id="basic-nav-dropdown" drop='down-centered'>
                  <NavDropdown.Item href="#software" onClick={() => handleClick("SOFTWARE", categoria1)} >SOFTWARE</NavDropdown.Item>
                  <NavDropdown.Item href="#videotutoriales" onClick={() => handleClick("VIDEOTUTORIALES", categoria1)} >VIDEOTUTORIALES</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#preguntas_frecuentes" onClick={() => handleClick("PREGUNTAS FRECUENTES", categoria1)} >PREGUNTAS FRECUENTES</Nav.Link>
                <Nav.Link href="#contacto" onClick={() => handleClick("CONTACTO", categoria1)} >CONTACTO</Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>



{/* Componente de chatbot */}



      <div>
        <button id="volverButton"

          style={{ right: isChatbotOpen ? '40px' : '150px', }}

          onClick={() => { scrollToTop() }}>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="30" height="30" fill="white"
            class="bi bi-chevron-compact-up"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z" />
          </svg>
        </button>

        <div onClick={() => handleClick("ChatBot", categoria7)}>
          <Chatbot filteredCategoriesPreguntas1={filteredCategoriesPreguntas1} preguntasPorCategoria={preguntasPorCategoria} chatbotInput={manejarCambioDesdeComponente} />
        </div>
      </div>



{/* Fondo */}



      <div>
        <div className="contenedor-imagen">
          <img src={banner} alt="Imagen" />
          <div className='filtro'></div>
          <div className="cuadro-contenedor cuadro1-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro2-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro3-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro4-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro5-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro6-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro7-contenedor">
            <div className="cuadro"></div>
          </div>
          <div className="cuadro-contenedor cuadro8-contenedor">
            <div className="cuadro"></div>
          </div>
          <img src={srcd_logo}
            style={{ maxWidth: '300px' }}
            alt="Segunda imagen"
            className="segunda-imagen" />
        </div>
      </div>

      <div className='container content'>



{/* Introducción */}



        <div className='contenedor-introduccion'>
            <div className='text-center pb-4 fontLatoBlack'>
              <h1>QUIÉNES SOMOS</h1>
              <div className="barraColores"></div>
            </div>

          <div className='pb-5'>
            {quienessomos.map(quienes => (<p className='fontLato pb-3'>{quienes.descripcion}</p>))}
          </div>
        </div>



{/* Profesionales */}



        <div id='profesionales'>
          <div style={{ paddingBottom: 20 }} className='divProfesionales' id="divProfesionales">

            <div className='text-center pb-4 fontLatoBlack'>
              <h1>EQUIPO DE SERVICIOS DIGITALES</h1>
              <div className="barraColores"></div>
            </div>

            <Col md={{ span: 8, offset: 2 }}>
              {profesionales.map(profesional => (
                <div className='cardProfesionales mb-5 fontLato' key={profesional.id_profesional}>
                  <Row>
                    <Col className='col-4 d-flex align-items-center justify-content-center'>
                      <div className='text-center'>
                        <Image src={profesional.imagen}
                          className="responsive-circle-image"
                          width={160} height={160}
                          roundedCircle />
                      </div>
                    </Col>
                    <Col>
                      <Row className='nombreProfesional'>
                        {profesional.nombre}
                      </Row>
                      <Row className='informacionProfesional'>
                        {profesional.descripcion}
                      </Row>
                      <Row className='correoProfesional'>
                        {profesional.correo}
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
          </div>
        </div>



{/* Software */}



        <div id='software'>
          <div style={{ paddingBottom: 30 }}>

            <div className='text-center pb-4 fontLatoBold'>
              <h2>¿BUSCAS ALGÚN SOFTWARE?</h2>
              <div className="barraColores"></div>
            </div>

            <div className='inputBusqueda fontLatoBold'>
              <div className="input-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
                <input type="text"
                id="busquedaCatalogoSoftware"
                placeholder="Ingresar Palabras Claves..."
                value={searchTerm}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm(value);
                  if (value === '') {
                    setSearchQuery('');
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                />
              </div>
              <button onClick={handleSearch}>
                <h2>BUSCAR</h2>
              </button>
            </div>
          </div>


          <div className='pb-5 pt-5 divSoftware center' id='divSoftware'>
            {filteredCategoriesSoftware.length === 0 ? (
              <div className='text-center fontLato'>
                <p>No se encontraron resultados para "{searchTerm}".</p>
              </div>
            ) : (
              <Accordion activeKey={activeAccordion} flush>
                {filteredCategoriesSoftware.map(([catalogo, softwares]) => (
                  <Accordion.Item
                    eventKey={catalogo}
                    key={catalogo}
                    onClick={() => handleClick("Despliegue de software " + catalogo, categoria3)}
                    style={{ marginBottom: '30px' }}>
                    <Accordion.Header className="fontMerriweather" 
                    onClick={() => handleAccordionClick(catalogo)}>
                      {catalogo}
                    </Accordion.Header>
                    <Accordion.Body>
                      {agruparPorPares(softwares).map((pair, index) => (
                        <Row key={index} className='mb-3'>
                          {pair.map((software) => (
                            <Col key={software.id}>
                              <Row>
                                <Col className="col-auto colBody">
                                  <div className="softwareCaratula">
                                  </div>
                                </Col>
                                <Col className="colInformacion">
                                  <Row className="tituloSoftware fontLato">
                                    {software.nombre}
                                  </Row>
                                  <Row className="descripcionSoftware fontLato">
                                    {software.descripcion}
                                  </Row>
                                  <Row className="descargarSoftware fontRoboto">
                                    <a href={software.url} download>
                                      <button className="botonDescargarSoftware text-center" onClick={() => handleClick("Boton Descarga de" + software.nombre, categoria3)} disabled={disabledButtons["Boton Descarga de" + software.nombre]}>
                                        DESCARGAR
                                      </button>
                                    </a>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </div>
        </div>



{/* Video Tutoriales */}



        <div id='videotutoriales'>
          <div style={{ paddingBottom: 80 }}>

            <div className='text-center pb-4 fontLatoBold'>
              <h2>¿BUSCAS ALGÚN VIDEOTUTORIAL?</h2>
              <div className="barraColores"></div>
            </div>

            <div className='inputBusqueda fontLatoBold'>
              <div className="input-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
                <input type="text"
                id="busquedaVideotutoriales"
                placeholder="Ingresar Palabras Claves..."
                value={searchTerm1}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm1(value);
                  if (value === '') {
                    setSearchQuery1('');
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch1();
                  }
                }}
                onClick={() => handleClick("busquedaVideotutoriales", categoria4)}
                />
              </div>
              <button onClick={handleSearch1}>
                <h2>BUSCAR</h2>
              </button>
            </div>
          </div>

          <div className='divVideotutoriales' style={{ marginBottom: 100 }} id='divVideotutoriales'>

            {filteredCategoriesVideos.length === 0 ? (
              <div className='text-center fontLato'>
                <p>No se encontraron resultados para "{searchTerm1}".</p>
              </div>
            ) : (
              <Accordion defaultActiveKey="0">
                {filteredCategoriesVideos.map(([categoria, videos], categoryIndex) => (
                  <Accordion.Item eventKey={categoryIndex} key={categoria}>
                    <Accordion.Header className="fontMerriweather">
                      {categoria}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="video-grid">
                        {videos.map((video, index) => {
                          const videoId = video.url.split('v=')[1];
                          const embedUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;

                          return (
                            <div key={index} className="video-item">
                              <p className="tituloVideo fontInter">
                                <img src={cetecom_logo2} width={25} height={25} alt="" /> {video.nombre}
                              </p>
                              <div className="contenedorReproductor" style={{ position: 'relative' }}>

                                <iframe
                                  id={`player-${videoId}`}
                                  title={`video-${index}`}
                                  allowFullScreen
                                  src={embedUrl}
                                  style={{ zIndex: 1 }}
                                ></iframe>

                                {/* Overlay invisible */}
                                <div
                                  onClick={() => handleVideoClick(videoId, video.nombre)}
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0)',
                                    cursor: 'pointer',
                                    zIndex: 2,
                                    pointerEvents: 'all',
                                  }}
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </div>

        </div>



{/* Preguntas frecuentes */}



        <div id='preguntas_frecuentes'>
          <div style={{ paddingBottom: 10 }}>

            <div className='text-center pb-4 fontLatoBold'>
              <h2>¿TIENES ALGUNA DUDA?</h2>
              <div className="barraColores"></div>
            </div>

            <div className='inputBusqueda fontLatoBold'>
              <div className="input-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
                <input type="text"
                id="busquedaPreguntasFrecuentes"
                placeholder="Ingresar Palabras Claves..."
                value={searchTerm2}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm2(value);
                  if (value === '') {
                    setSearchQuery2('');
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch2();
                  }
                }}
                onClick={() => handleClick("busquedaPreguntasFrecuentes", categoria5)}
                />
              </div>
              <button onClick={handleSearch2}>
                <h2>BUSCAR</h2>
              </button>
            </div>
          </div>

          <div className='pt-5 divPreguntasFrecuentes' style={{ marginBottom: 50 }} id="divPreguntasFrecuentes">
            {filteredCategoriesPreguntas.length === 0 ? (
              <div className='text-center fontLato'>
                <p>No se encontraron resultados para "{searchTerm2}".</p>
              </div>
            ) : (
              <Accordion activeKey={activeAccordion} flush>
                {filteredCategoriesPreguntas.map(([categoria, preguntas]) => (
                  <Accordion.Item
                  eventKey={categoria}
                  key={categoria}
                  onClick={() => handleClick(categoria, categoria5)}
                  style={{ marginBottom: '30px' }}>
                    <Accordion.Header className="fontMerriweather"
                    onClick={() => handleAccordionClick(categoria)}>
                          {categoria}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="cuadroPreguntasFrecuentes">
                        {preguntas.map((pregunta) => (
                          <div key={pregunta.id} className="fontLato">
                            <Row className="pregunta">
                              {pregunta.pregunta}
                            </Row>
                            <Row className="respuesta pb-5">
                              {pregunta.respuesta}
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </div>
        </div>



{/* Contacto */}



        <div id='contacto'>
          <div className='divContacto fontLato' id="divContacto">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h1>Contáctanos</h1>
                </Accordion.Header>
                <Accordion.Body>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <div className='encabezadoInput'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                        </svg>
                        Nombre
                      </div>
                      <div className="input-container">
                        <input type="text" id="input-field" autoComplete='off' value={inputNombre} onChange={handleInputNombreChange}></input>
                        {isInputNombreValid && <span className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" className="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                          </svg>
                        </span>}
                      </div>
                      <div className='encabezadoInput'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                        </svg>
                        Correo
                      </div>
                      <div className="input-container">
                        <input type="text" id="input-field" autoComplete='off' value={email} onChange={handleEmailInput}></input>
                        {isEmailValid && <span className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" className="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                          </svg>
                        </span>}
                      </div>
                      <div className='avisoCorreo'>
                        <p>Recuerda utilizar tu correo @duocuc.cl</p>
                      </div>

                      <div className='encabezadoInput'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="25" fill="currentColor" className="bi bi-chat-square-text" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                        </svg>
                        Mensaje
                      </div>
                      <div className="input-container">
                        <textarea className='mensaje' id="input-field" value={inputMensaje} onChange={handleInputMensajeChange}></textarea>


                        {isInputMensajeValid && <span className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" className="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                          </svg>
                        </span>}
                      </div>
                    </Row>

                    <div className='myCaptcha'>
                      <ReCAPTCHA sitekey='6LfopOYpAAAAANsfkpzT9fu14Fau4SIWkML9QH8H' onChange={validarCaptcha} onExpired={invalidarCaptcha}></ReCAPTCHA>
                    </div>


                    {!isEmailValid || !isInputNombreValid || !isInputMensajeValid || !isCaptchaValid ? (
                      <div className='btnEnviarDeshabilitado'>
                        <button disabled>
                          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" fill="white" className="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                          </svg>
                        </button>
                      </div>
                    ) : (

                      <div className='btnEnviar'>
                        <button type='submit' disabled={isSubmitDisabled} onClick={() => handleClick("Envio Formulario", categoria6)} >
                          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" fill="white" className="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <Modal show={estadoModal} onHide={cerrarModal} className='modalContactanos fontMohave'>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <img src={chatAvatar} alt="" width={91.78} height={90} className='mb-3' />
              <br></br>
              <p className='msg'>
                {mensajeModal}
              </p>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cerrarModal}>
                Aceptar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>



{/* Footer */}



      <div>
        <footer class="myFooter">
          <div class="container contenedorFooter">
            <div class="row " >

              <div >

                <div class="barrafooter">
                </div>
                <div>
                  <h2 className='fontMerriweather pb-3' style={{ marginLeft: "30px" }}>NOSOTROS</h2>
                </div>
              </div>

              <div class="col-12 col-md-4 web-margin-footer">
                <ul class="footer-nav">
                  <li><a href="https://www.duoc.cl/nosotros/" class="footer-link">SOBRE DUOC UC</a></li>
                  <li><a href="https://www.duoc.cl/nosotros/donde-estamos/sedes/" class="footer-link">SEDES Y CAMPUS</a></li>
                  <li><a href="https://www.duoc.cl/sala-de-prensa/" class="footer-link">SALA DE PRENSA</a></li>
                  <li><a href="https://extension.duoc.cl/" class="footer-link">EXTENSIÓN</a></li>
                  <li><a href="https://www.duoc.cl/nosotros/vinculacionconelmedio/" class="footer-link">VINCULACIÓN CON EL MEDIO</a></li>
                </ul>
              </div>
              <div class="col-12 col-md-6 web-margin-footer" >
                <ul class="footer-nav">
                  <li><a href="https://www.duoc.cl/nosotros/investigacion-aplicada/" class="footer-link">INVESTIGACIÓN APLICADA E INNOVACIÓN</a></li>
                  <li><a href="https://www.duoc.cl/vida-estudiantil/innovacion-emprendimiento/" class="footer-link">INNOVACIÓN Y EMPRENDIMIENTO ESTUDIANTIL</a></li>
                  <li><a href="https://www.duoc.cl/internacionalizacion/" class="footer-link">INTERNACIONALIZACIÓN</a></li>
                  <li><a href="https://www.duoc.cl/nosotros/calidad-acreditacion/" class="footer-link">CALIDAD Y ACREDITACIÓN</a></li>
                  <li><a href="https://www.duoc.cl/nosotros/sobre-duoc/transparencia/" class="footer-link">TRANSPARENCIA</a></li>
                  <li><a href="https://www.duoc.cl/nosotros/sobre-duoc/transparencia/memoria-anual/" class="footer-link">MEMORIA ANUAL</a></li>
                  <li><a href="https://www.duoc.cl/proyectos-servicios/" class="footer-link">PROYECTOS Y SERVICIOS</a></li>
                </ul>
              </div>


            </div>
            <div >
              <img src={srcd_logo} class="footer-logo" />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
