import React from 'react';
import { useState, useEffect, useRef } from 'react';
/*import { useLocation } from 'react-router-dom';  // Importa useLocation - funcion detectar pagina beta*/
import './chatbot.css';
import axios from 'axios'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import chatAvatar from '../chatbot-avatar.png'


/* Las variables filteredCategoriesPreguntas1 y preguntasPorCategoria llegan desde la app principal con los datos filtrados para mostrarse en el chatbot, La variable chatbotInput se ocupa para filtrar estos datos*/
/* aunque ahora se usa input de usuario en chat solamente */
const Chatbot = ({ filteredCategoriesPreguntas1, preguntasPorCategoria, chatbotInput }) => {

  // Verifica la ruta actual del navegador
  const [isPathAllowed, setIsPathAllowed] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const allowedPath = process.env.REACT_APP_ALLOWED_PATH || '/beta';

    // Establece si la ruta actual es la ruta permitida
    setIsPathAllowed(currentPath.startsWith(allowedPath));
  }, []); // Se ejecuta una vez cuando el componente se monta

  ////fin /beta

  /* Constante para controlar la visualizacion del chat*/
  const [showChat, setShowChat] = useState(false);
  /* Constante para el input de chatbot */
  const [chatBotInputValue, setChatBotInputValue] = useState('');

  const [isTyping, setIsTyping] = useState(false);
  const [chatResponse, setChatResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /* constante para array del historial del chat */

  const [chatHistorial, setChatHistorial] = useState(() => {
    const savedHistorial = localStorage.getItem("chatHistorial");
    return savedHistorial
      ? JSON.parse(savedHistorial)
      : [{ rol: 'bot', mensaje: 'Hola soy tu asistente virtual. ¿Cómo puedo ayudarte?' }];
  });
  /* Funcion para alternar la visualizacion del chatbot */
  const toggleChat = () => {
    setShowChat(!showChat);

    if (!showChat) {

      window.dispatchEvent(new CustomEvent('chatbotOpened'));

      setTimeout(() => {
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else {

      window.dispatchEvent(new CustomEvent('chatbotClosed'));
    }

  };




  /* Guardar el historial de chat en localStorage */
  useEffect(() => {
    localStorage.setItem("chatHistorial", JSON.stringify(chatHistorial));
  }, [chatHistorial]);

  const endOfMessagesRef = useRef(null);
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistorial]);


  const handleInputChange = (e) => {
    setChatBotInputValue(e.target.value);
    chatbotInput(e.target.value);
    setIsTyping(true);
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsTyping(false);
      setIsLoading(true);

      try {
        const response = await axios.post('/consulta', {
          input: chatBotInputValue,
          historial: chatHistorial,  // recuperar historial en texto
        });
        console.log('Response: ', response);
        console.log('Error 1: ', response.data.error_t);

        setChatHistorial(prev => [
          ...prev,
          { rol: 'user', mensaje: chatBotInputValue },
          { rol: 'bot', mensaje: response.data.response }
        ]);

        setChatResponse(response.data.response);



      } catch (error) {
        console.log('Error:', error);
      } finally {
        setIsLoading(false);
        setChatBotInputValue("");
        console.log(chatHistorial);

      }
    }
  };

  //para limpiar cache del chat (borrar los datos)
  //localStorage.removeItem('chatHistorial');





  return (

    /*validar que se use direccion /beta en chat*/

    <div>
      {isPathAllowed && (
      <button id="chatButton" onClick={toggleChat}>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="30" height="30" fill="white"
          className="bi bi-chat-left-text"
          viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
        </svg>
      </button>
      )}

      <div id="chatWindow" style={{ display: showChat ? 'block' : 'none' }}>
        <div className='maxHeight'>
          <div id='chatbotHeader'>
            <h1 className='lato'> SOPORTE </h1>
            <button id="btnCerrar" onClick={toggleChat}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </div>



          <div className='contentChatbot fontLato'>
            <div className='contenedorChatbotSuperior'>
              <div id="MiniBannerChat">
                <div>Considera que nuestro chat está en aprendizaje continuo.</div>
              </div>
              {Array.isArray(chatHistorial) && chatHistorial.map((entry, index) => (
                <Row className='dialogo' key={index}>
                  {entry.rol === 'bot' && (
                    <Col className='col-auto imagen'>
                      <img
                        src={chatAvatar}
                        className="d-inline-block align-top chat-avatar"
                        alt=""
                      />
                    </Col>
                  )}
                  <Col>


                    <div style={{ display: 'flex', justifyContent: entry.rol === 'bot' ? 'flex-start' : 'flex-end' }}>
                      {entry.rol === 'bot' ? (
                        <div className="cuadro-dialogo bot" >
                          <div className="triangulo bot" ></div>
                          <p >{entry.mensaje}</p>
                        </div>
                      ) : (
                        <div className="cuadro-dialogo user" >
                          <div className="triangulo user" ></div>
                          <p>{entry.mensaje}</p>
                        </div>
                      )}
                    </div>


                  </Col>
                </Row>

              ))}

              {chatBotInputValue !== '' && (
                <div style={{ margin: "20px", display: 'flex', justifyContent: 'flex-end', marginRight: "50px" }}>
                  {isTyping && (
                    <div className='typingIndicator'>
                      <span className="ellipsis"></span>
                      <span className="ellipsis"></span>
                      <span className="ellipsis"></span>
                    </div>
                  )}
                </div>
              )}


              <div ref={endOfMessagesRef} >
              </div>
            </div>

            <div className='chatBotInputContainer'>
              <input
                value={chatBotInputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder='Escribe tus dudas'
              />


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;